import { createRouter, createWebHistory } from "vue-router";
import { AuthenticationCookieManager } from "../services/AuthenticationCookieManager";
import Home from "../views/home-page.vue";
import NotFoundPage from '../views/not-found-page.vue'
import defaultLayout from "../layouts/side-nav-outer-toolbar.vue";
import simpleLayout from "../layouts/single-card.vue";
import errorLayout from '../layouts/error-layout.vue'
import { RouteNames } from "./RouteNames";
import { useCurrentUserStore } from "@/stores/CurrentUserStore";
import { isPropertyButtonUrl } from "@/utils/system/EnvironmentReader";
import { useLoginStore } from "@/stores/LoginStore";

function loadView(view :string) {
  return () => import (/* webpackChunkName: "login" */ `../views/${view}.vue`)
}

const router = createRouter({
  routes: [
    {
      path: "/home",
      name: RouteNames.Home,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/tasks",
      name: RouteNames.Tasks,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('tasks-list')
    },
    {
      path: "/keys",
      name: RouteNames.Keys,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('key-list')
    },
    {
      path: "/contacts",
      name: RouteNames.ContactList,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('contacts-list')
    },
    {
      path: "/support-cases",
      name: RouteNames.SupportCases,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('support-cases-list')
    },
    {
      path: "/support-cases/:switchid",
      name: RouteNames.UtilitySwitchSupportCases,
      props: true,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('support-cases-list')
    },    
    {
      path: "/login-form",
      name: RouteNames.LoginForm,
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form"),
      beforeEnter: () => {
        if (isUserLoggedIn()) {
          return { name: RouteNames.Home, replace: true }
        }
      }
    },
    {
      path: "/auto-login",
      name: RouteNames.AutoLogin,
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "AutoLogin"
      },
      component: loadView("login-form"),
    },
    {
      path: "/reset-password",
      name: RouteNames.ResetPassword,
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password"
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/reset-password-confirmation/:token",
      name: RouteNames.ResetPasswordConfirmation,
      props: true,
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password"
      },
      component: loadView("reset-password-confirmation")
    },
    {
      path: "/applicants",
      name: RouteNames.Applicants,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('applicants-list')
    },
    {
      path: "/properties",
      name: RouteNames.Properties,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('property-list')
    },
    {
      path: "/property-offers",
      name: RouteNames.PropertyOffers,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('property-offers-list')
    },
    {
      path: '/contact/:contactid',
      props: true,
      name: RouteNames.ContactPage,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('contact-page')
    },
    {
      path: '/property/:propertyid',
      props: true,
      name: RouteNames.PropertyPage,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('property-page')
    },
    {
      path: '/certificate/:certificateid',
      props: true,
      name: RouteNames.CertificatePage,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('certificate-page')
    },
    {
      path: '/switches',
      props: true,
      name: RouteNames.UtilitySwitchesList,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('utility-switches-list')
    },
    {
      path: '/appointments',
      props: true,
      name: RouteNames.AppoinmentsPage,
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('appointments-list')
    },
    {
      path: '/test',
      props: true, 
      name: RouteNames.Test, 
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('test-page')
    },
    {
      path: '/configuration',
      props: true, 
      name: RouteNames.Configuration, 
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('configuration-page')
    },
    {
      path: '/userManagement',
      props: true, 
      name: RouteNames.UserManagement, 
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('user-management')
    },
    {
      path: '/certificates',
      props: true, 
      name: RouteNames.CertificateList, 
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('certificate-list')
    },
    {
      path: '/diary',
      props: true, 
      name: RouteNames.Diary, 
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('diary-page')
    },
    {
      path: '/web-viewings',
      props: true, 
      name: RouteNames.WebViewings, 
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('web-viewings-list')
    },
    {
      path: '/property-marketing',
      props: true, 
      name: RouteNames.PropertyMarketingList, 
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView('property-marketing-list')
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: '/:pathMatch(.*)*',
      name: RouteNames.NotFound,
      component: NotFoundPage,
      meta: {
        layout: errorLayout
      }
    }
  ],
  history: createWebHistory()
});

router.beforeEach(() => {
  // To avoid confusion with utility switching customers, ensure browser title is set appropiately
  document.title = isPropertyButtonUrl() ? 'Property Button' : 'Acquaint CRM'
});

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth) {
      if (!isUserLoggedIn()) {
          return { name: 'login-form' };
      }
  }
});

router.beforeEach(async (to) => {
  if (!to.meta.requiresAuth || !isUserLoggedIn()) {
    return;
  }

  /* To prevent left nav menu 'flickering' whilst loading for the first time
   * Ensure current user details have been cached */
  const currentUserStore = useCurrentUserStore();
  await currentUserStore.getCurrentUserDetails();
})

/** Checks whether the user is currently logged in */
const isUserLoggedIn = () => {
  const authenticationCookieManager = new AuthenticationCookieManager();
  const tokenCookie = authenticationCookieManager.getDatabaseConnectorToken();

  return !!tokenCookie?.token;
}

router.beforeEach(async (to) => {
  if (to.name != RouteNames.AutoLogin) {
    return;
  }

  if (!to.query?.data) {
    return { name: RouteNames.LoginForm }
  }

  const loginStore = useLoginStore();
  let loginSuccess = false;

  try {
    loginSuccess = await loginStore.autoLogin(to.query.data.toString());    
  } catch(err) {
    console.error(err);
    loginSuccess = false;
  }

  if (!loginSuccess) {
    return { name: RouteNames.LoginForm }
  }  
});

export default router;
