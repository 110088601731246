export enum RouteNames {
    Home = 'home',
    Tasks = 'tasks',
    Keys = 'keys',
    LoginForm = 'login-form',
    ResetPassword = 'reset-password',
    ResetPasswordConfirmation = 'reset-password-confirmation',
    ContactList ='contacts',
    SupportCases = 'support-cases',
    Applicants='applicants',
    Properties='properties',
    ContactPage = 'contact',
    UtilitySwitchesList = 'utility-switches',
    Test = 'test',
    PropertyPage = 'property',
    NotFound = 'not-found',
    Configuration = 'configuration',
    UserManagement = 'user-management',
    UtilitySwitchSupportCases = 'utility-switch-support-cases',
    PropertyOffers = 'property-offers',
    Diary = 'diary',
    CertificateList = 'certificates',
    CertificatePage = 'certificate',
    AppoinmentsPage = 'appointments',
    AutoLogin = 'auto-login',
    WebViewings = 'web-viewings',
    PropertyMarketingList = 'property-marketing'
}