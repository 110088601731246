import { Environment } from "@/enums/system/Environment";
import { HttpMethod } from "@/enums/system/http/HttpMethod";
import { JwtToken } from "@/types/authentication/JwtToken";
import { IJwtAuthClient } from "@/types/services/IJwtAuthClient";
import { IApiClient } from "@/types/services/IApiClient";
import { getEnvironment } from "@/utils/system/EnvironmentReader";
import { App, Plugin } from "vue";
import { LoginCredentials } from "@/types/authorization/LoginCredentials";
import { Mutex } from 'async-mutex';
import { writeCookie } from "@/utils/cookies/JsonCookieWriter";
import { readCookie } from "@/utils/cookies/JsonCookieReader";

export class AcquaintApiNextClient implements IApiClient, IJwtAuthClient<LoginCredentials> {
    private _mutex = new Mutex();
    private _jwtToken: JwtToken = { token: '', expiresIn: 0, utcExpiryDate: '' }
    private _baseUrl: string

    constructor() {
        this._baseUrl = this.getApiBaseUrl();
    }

    public async authenticate(credentials: LoginCredentials): Promise<JwtToken> {
        const response = await fetch(`${this._baseUrl}/auth/login`, {
            method: HttpMethod.Post,
            mode: 'cors',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(credentials),
            credentials: 'include'
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw Error(responseText);
        }

        this._jwtToken = await response.json() as JwtToken;

        writeCookie('x-jwt-auth', this._jwtToken, 0);

        return this._jwtToken;
    }

    public async refreshJwtToken(): Promise<JwtToken> {
        const response = await fetch(`${this._baseUrl}/auth/refresh-token`, {
            method: HttpMethod.Post,
            mode: 'cors',
            headers: {
                'content-type': 'application/json'
            },
            credentials: 'include'
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw Error(responseText);
        }

        writeCookie('x-jwt-auth', this._jwtToken, 0);
        this._jwtToken = await response.json() as JwtToken;
        return this._jwtToken;
    }

    /** Gets the base url to use for api calls based on the current environment */
    private getApiBaseUrl() {
        const environment = getEnvironment();

        switch (environment) {
            case Environment.Development:
                return 'https://acquaintapinetcore-dev.azurewebsites.net';
            case Environment.Staging:
                return 'https://acquaintapinetcore-staging.azurewebsites.net';
            default:
                return 'https://acquaintapinetcore.azurewebsites.net'
        }
    }

    public async httpGet<T>(url: string): Promise<T> {
        const response = await this.sendHttpRequest(url, HttpMethod.Get);
        return await this.parseResponse(response);
    }

    public async getFile(url: string): Promise<Blob> {
        const response = await this.sendHttpRequest(url, HttpMethod.Get);
        return response.blob();
    }

    public async httpPut<T>(url: string, data: any): Promise<T> {
        const response = await this.sendHttpRequest(url, HttpMethod.Put, data);
        return await this.parseResponse(response);
    }

    public async httpPost<T>(url: string, data: any): Promise<T> {
        const response = await this.sendHttpRequest(url, HttpMethod.Post, data);
        return await this.parseResponse(response);
    }

    public async httpDelete<T>(url: string, data: any): Promise<T> {
        const response = await this.sendHttpRequest(url, HttpMethod.Delete, data);
        return await this.parseResponse(response);
    }

    public async httpPutVoid(url: string, data: any): Promise<void> {
        await this.sendHttpRequest(url, HttpMethod.Put, data);
    }

    public async httpPostVoid(url: string, data: any): Promise<void> {
        await this.sendHttpRequest(url, HttpMethod.Post, data);
    }

    public async httpDeleteVoid(url: string, data: any): Promise<void> {
        await this.sendHttpRequest(url, HttpMethod.Delete, data);
    }

    /** Sends a Http Request to the passed url
     * @param url url to send the request to
     * @param method the HTTP Method to use when sending the request
     * @param data data to send in the body of the request (optional)
     * @returns response of the request */
    private async sendHttpRequest(url: string, method: HttpMethod, data: any = null): Promise<Response> {
        // Ensure pased url is formatted correctly to avoid unnecessary api errors
        if (!url.startsWith('/')) {
            url = `/${url}`
        }

        /* The jwt token may not exist if the login was bypassed due to previous session being alive
         * This is most likely due to timing issues between the 2 api authentication systems */
        if (!this._jwtToken?.token) {
            this.getJwtFromCookie();
        }

        // To avoid unauthorised errors ensure Jwt token is valid
        if (this.isJwtTokenRefreshRequired()) {
            /* To prevent our client recalling the server and getting multiple JWT tokens run exclusively.
               Once this process is unlocked if this is the first to call it will then call the API, if another process
               unlocks before it will load the jwt so this will skip the auth API call */
            await this._mutex.runExclusive(async () => {
                if (this.isJwtTokenRefreshRequired()) {
                    await this.refreshJwtToken();
                }
            });
        }

        if (data) {
            data = JSON.stringify(data);
        }

        const response = await fetch(`${this._baseUrl}${url}`, {
            method,
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + this._jwtToken.token,
                'content-type': 'application/json'
            },
            body: data
        });

        // Report errors back to end user to additional errors processing responses
        if (!response.ok) {
            const responseText = await response.text();
            throw Error(responseText);
        }

        return response;
    }

    /** Parses the response based on the response content-type header */
    private async parseResponse<T>(response :Response) {
        const contentType = response.headers.get("content-type");

        if (contentType?.includes('application/json')) {
            return await response.json() as T;
        } else {
            return await response.text() as unknown as T;
        }
    }

    /** Sends a Http Request with form data to the passed url
     * @param url url to send the request to
     * @param data form data to send in the body of the request
     * @returns response of the request */
    public async sendFormData(url: string, data: any): Promise<Response> {
        // Ensure pased url is formatted correctly to avoid unnecessary api errors
        if (!url.startsWith('/')) {
            url = `/${url}`
        }

        /* The jwt token may not exist if the login was bypassed due to previous session being alive
         * This is most likely due to timing issues between the 2 api authentication systems */
        if (!this._jwtToken?.token) {
            this.getJwtFromCookie();
        }

        // To avoid unauthorised errors ensure Jwt token is valid
        if (this.isJwtTokenRefreshRequired()) {
            /* To prevent our client recalling the server and getting multiple JWT tokens run exclusively.
               Once this process is unlocked if this is the first to call it will then call the API, if another process
               unlocks before it will load the jwt so this will skip the auth API call */
            await this._mutex.runExclusive(async () => {
                if (this.isJwtTokenRefreshRequired()) {
                    await this.refreshJwtToken();
                }
            });
        }

        const response = await fetch(`${this._baseUrl}${url}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + this._jwtToken.token,
            },
            body: data
        });

        // Report errors back to end user to additional errors processing responses
        if (!response.ok) {
            const responseText = await response.text();
            throw Error(responseText);
        }

        return response;
    }

    /** Attempts to read the cached jwt from a cookie */
    private getJwtFromCookie() {
        const cookieToken: JwtToken | undefined = readCookie('x-jwt-auth');

        if (cookieToken) {
            this._jwtToken = cookieToken;
        }
    }

    /** Checks whether the current jwt token has expired */
    private isJwtTokenRefreshRequired(): boolean {
        if (!this._jwtToken?.token || !this._jwtToken?.utcExpiryDate) {
            return true;
        }

        const fiveMinutuesInMilliseconds = 300000;
        const nowWithRefreshOffset = Date.now() - fiveMinutuesInMilliseconds;
        return new Date(this._jwtToken.utcExpiryDate) <= new Date(new Date(nowWithRefreshOffset).toUTCString());
    }
}

export const AcquaintApiNextClientPlugin: Plugin = {
    install: (app: App) => {
        const client = new AcquaintApiNextClient();
        app.provide('AcquaintApiNextClient', client);
    }
}